import { S3 } from "aws-sdk"

const s3 = new S3({
  region: "ap-northeast-2",
  accessKeyId: "AKIAZIYDAYUEMOPJA6L3",
  secretAccessKey: "dDuE4vB/tFoGKbr9ZhXEJXwYsV1yuBRv4DpCQuEd",
})

export const uploadToS3 = async (imageData) => {
  // const base64Data = Buffer.from(
  //   imageData.replace(/^data:image\/\w+;base64,/, ""),
  //   "base64"
  // )

  const blobData = await fetch(imageData).then((res) => res.blob())

  const key = `images/${Date.now()}.jpg`

  const params = {
    Bucket: "facemesh",
    Key: key, // 파일 이름 설정 (여기서는 현재 시간을 사용)
    Body: blobData,
    ContentType: "image/jpeg",
  }

  try {
    const { Location } = await s3.upload(params).promise()

    // 해당 객체에 대한 presigned URL 얻기
    const presignedUrl = await s3.getSignedUrlPromise("getObject", {
      Bucket: params.Bucket,
      Key: key,
      Expires: 600, // URL이 만료되는 시간 (60초)
    })

    return presignedUrl
  } catch (error) {
    throw error
  }
}
